import React from "react"
import {graphql, StaticQuery} from "gatsby"
import * as Yup from 'yup';

import FormService from './../../lib/service/form'

import Form from './../../components/form'

import styles from './component.module.less'

// @see https://github.com/jquense/yup

export default class ContactForm extends React.Component {

    locale;
    setSent;
    formService;

    constructor(props) {
        super(props);

        this.state = {
            submitting: false,
        };

        this.setSent = props.setSent || (() => {});

        this.locale = props.locale || 'de';

        this.formService = new FormService(process.env.GATSBY_API_FORM_URL, this.locale);

        this.onSubmit = this.onSubmit.bind(this);
    }

    fields(txt) {
        return {
            salutation: {
                type: 'select',
                label: this.props.labels.salutation,
                placeholder: this.props.placeholders.salutation,
                values: this.props.fieldValues.salutation,
            },
            forename: {
                type: 'text',
                label: this.props.labels.surname,
                placeholder: this.props.placeholders.surname,
            },
            name: {
                type: 'text',
                label: this.props.labels.lastname,
                placeholder: this.props.placeholders.lastname,
            },
            email: {
                type: 'email',
                label: this.props.labels.email,
                placeholder: this.props.placeholders.email,
            },
            phone: {
                type: 'number',
                label: this.props.labels.phone,
                placeholder: this.props.placeholders.phone,
            },
            message: {
                type: 'textarea',
                label: this.props.labels.message,
                placeholder: this.props.placeholders.message,
            },
            callback: {
                type: 'text',
                label: txt.formLabelCallback,
                placeholder: txt.formPlaceholderCallback,
            },
            privacy: {
                type: 'checkbox',
                labelParts: this.props.labels.privacy.split('%'),
                labelLinkUrl: this.props.privacyUrl,
            }
        };
    }

    schema() {
        return Yup.object().shape({
            salutation: Yup.string().oneOf(this.props.fieldValues.salutation).required(),
            forename: Yup.string().required(),
            name: Yup.string().required(),
            email: Yup.string().email().required(),
            phone: Yup.string(),
            message: Yup.string().required(),
            privacy: Yup.bool().default(false).oneOf([true]).required(),
            callback: Yup.string(),
        });
    }

    onSubmit(values, setSubmitting) {
        this.setState({
            submitting: true,
        });

        const formData = JSON.parse(JSON.stringify(values));
        formData.privacy = formData.privacy === true ? 1 : 0;

        this.formService.submit('contact', formData).then(errors => {
            setSubmitting(false);
            this.setState({
                submitting: false,
            });

            if (errors) {
                alert(
                    Object.keys(errors).map(name => {
                        return this.fields[name].label + ':\n' +
                            errors[name].map(msg => ' - ' + msg).join('\n');
                    }).join('\n')
                );
            } else {
                this.setSent(true);
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({'event': 'gg_form_contact_sent'});
            }
        }).catch(() => {
            alert(this.props.errorText);
        });
    }

    render() {
        return (
            <StaticQuery
                query={graphql`
                    query {
                        allDatoCmsTemplateTexte {
                            nodes {
                                locale
                                formContactSubmitLabel
                                
                                formLabelCallback
                                formPlaceholderCallback
                            }
                        }
                    }
                `}
                render={data => {
                    const templateStrings = data.allDatoCmsTemplateTexte.nodes.find(t => t.locale === this.locale) || {};

                    return (
                        <Form
                            id="contact"
                            locale={this.locale}
                            fields={this.fields(templateStrings)}
                            schema={this.schema()}
                            theme={this.props.theme}
                            onSubmit={this.onSubmit}
                        >
                            <button
                                type="submit"
                                className={styles.submit}
                                ref={this.props.submitRef}
                            >{templateStrings.formContactSubmitLabel}</button>
                        </Form>
                    );
                }}
            />
        )
    }
}
