import React from "react"
import {graphql, StaticQuery} from "gatsby"
import * as Yup from "yup";

// @see https://github.com/jquense/yup

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                allDatoCmsConfig {
                    nodes {
                        locale
                        pageDataProtection {
                            slug
                        }
                    }
                }
            
                allDatoCmsTemplateTexte {
                    nodes {
                        locale
                        
                        formSentHeadline
                        formErrorTxt
                        
                        formValidationRequired
                        formValidationEmail
                        
                        formLabelSalutation
                        formLabelSurname
                        formLabelLastname
                        formLabelEmail
                        formLabelPhone
                        formLabelMessage
                        formLabelNewsletterSubscribe
                        formPrivacyTxt
                        
                        formPlaceholderSalutation
                        formPlaceholderSurname
                        formPlaceholderLastname
                        formPlaceholderEmail
                        formPlaceholderPhone
                        formPlaceholderMessage
                        
                        formValuesSalutation
                    }
                }
            }
        `}
        render={data => {
          const config = data.allDatoCmsConfig.nodes.find(t => t.locale === (props.locale || 'de'));
          const templateTxt = data.allDatoCmsTemplateTexte.nodes.find(t => t.locale === (props.locale || 'de'));

          let privacyUrl;
          let sentHeadline;
          let errorText;

          let labels = {};
          let placeholders = {};
          let fieldValues = {};

          if (templateTxt) {
            Yup.setLocale({
              mixed: {
                required: templateTxt.formValidationRequired,
                oneOf: templateTxt.formValidationRequired,
              },
              string: {
                email: templateTxt.formValidationEmail,
              },
            });

            privacyUrl = '/' + (props.locale || 'de') + '/' + config.pageDataProtection.slug + '/';

            sentHeadline = templateTxt.formSentHeadline;
            errorText = templateTxt.formErrorTxt;

            labels = {
              salutation: templateTxt.formLabelSalutation,
              surname: templateTxt.formLabelSurname,
              lastname: templateTxt.formLabelLastname,
              email: templateTxt.formLabelEmail,
              phone: templateTxt.formLabelPhone,
              message: templateTxt.formLabelMessage,
              newsletterSubscribe: templateTxt.formLabelNewsletterSubscribe,
              privacy: templateTxt.formPrivacyTxt,
            };

            placeholders = {
              salutation: templateTxt.formPlaceholderSalutation,
              surname: templateTxt.formPlaceholderSurname,
              lastname: templateTxt.formPlaceholderLastname,
              email: templateTxt.formPlaceholderEmail,
              phone: templateTxt.formPlaceholderPhone,
              message: templateTxt.formPlaceholderMessage,
            };

            fieldValues = {
              salutation: templateTxt.formValuesSalutation.split('\n'),
            };
          }

          return (React.cloneElement(props.children, {privacyUrl, sentHeadline, errorText, labels, placeholders, fieldValues}));
        }}
    />
)
