import React, {useState} from "react"
import {graphql} from "gatsby"

import Layout from '../layouts/fullscreen'

import ContentContainer from '../components/content-container'
import Button from '../components/button'
import Text from '../components/text'
import ContactForm from '../forms/contact'

import styles from './contact/component.module.less'

export default ({data, pageContext}) => {

  const locale = pageContext.locale || 'de';

  const config = data.allDatoCmsConfig.nodes[0];

  const templateTxt = data.allDatoCmsTemplateTexte.nodes[0];

  const submitRef = React.createRef();

  const [sent, setSent] = useState(false);

  const goBack = () => {
    const history = (typeof window !== 'undefined') ? window.history : {go: () => {}};
    history.go(-1);
  };

  return (
      <Layout
          locale={locale}
          localeLinks={pageContext.localeLinks}
          seo={data.datoCmsSeiten.seoMetaTags}
          robots="noindex,nofollow"
          asideTextHtml={templateTxt.formContactTxt}
      >
        <div className={styles.content}>
          {!sent &&
            <Button className={styles.backButtonMobile} theme="outline" onClick={goBack}>{templateTxt.formBackLabel}</Button>
          }

          <ContentContainer tag="header">
            <Text
                title={templateTxt.formContactTitleLabel}
                headline={!sent ? templateTxt.formContactHeadlineLabel : templateTxt.formSentHeadline}
            >
              {!sent && <p>{templateTxt.formReqFieldsTxt}</p>}
              {sent && templateTxt.formSentTxt && <p>{templateTxt.formSentTxt}</p>}
            </Text>

            {sent &&
              <Button className={styles.backHomeBtn} theme="block" href={'/' + locale + '/'}>{templateTxt.formHomeButtonLabel}</Button>
            }
          </ContentContainer>

          <div className={sent ? styles.formHide : ''}>
            <ContactForm
                submitRef={submitRef}
                locale={locale}
                setSent={setSent}
            />
          </div>
        </div>

        {!sent &&
          <footer className={styles.footer}>
            <div className={styles.footerContent}>
              <Button className={styles.backButton} theme="outline" onClick={goBack}>{templateTxt.formBackLabel}</Button>
              <Button className={styles.submitButton} theme="block" onClick={() => submitRef.current.click()}>
                {templateTxt.formContactSubmitLabel}
              </Button>
            </div>
          </footer>
        }

        {config.contactSidebarImage &&
            <div
                className={styles.deco}
                style={{
                  backgroundImage: 'url(' + config.contactSidebarImage.fluid.src + ')',
                }}
            />
        }

      </Layout>
  );
}

export const query = graphql`
    query($id: String!, $locale: String!) {
        datoCmsSeiten(id: {eq: $id }) {
            seoMetaTags {
                tags
            }
        }
        
        allDatoCmsConfig(filter: {locale: {eq: $locale}}) {
            nodes {
                contactSidebarImage {
                    fluid(imgixParams: {w: "700", h: "700", auto: "compress", fit: "crop", dpr: 1.5, q: 75}) {
                        src
                    }
                }
            }
        }
        
        allDatoCmsTemplateTexte(filter: {locale: {eq: $locale}}) {
            nodes {
                formBackLabel
                formSentHeadline
                formSentTxt
                formHomeButtonLabel
                formReqFieldsTxt
                
                formContactTitleLabel
                formContactHeadlineLabel
                formContactSubmitLabel
                formContactTxt
            }
        }
    }
`;
