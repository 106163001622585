import React from "react"

import styles from './component.module.less'

export default function(props) {
  let classNames = [styles.txt];

  if (props.dark && props.dark === true) {
    classNames.push(styles.dark);
  }
  if (props.center && props.center === true) {
    classNames.push(styles.center);
  }
  if (props.header && props.header === true) {
    classNames.push(styles.header);
  }
  if (props.className) {
    classNames.push(props.className);
  }

  let headlineClassNames = [styles.headline];
  if (props.headlineClassName) {
    headlineClassNames.push(props.headlineClassName);
  }
  if (props.htmlHeadline) {
    headlineClassNames.push(styles.child);
  }
  headlineClassNames = headlineClassNames.join(' ');

  return (
      <div id={props.id} className={classNames.join(' ')}>
        {props.title && props.title.length > 0 && <span className={`${styles.title}${props.titleClass ? ' ' + props.titleClass : ''}`}>{props.title}</span>}

        {props.titleHtml && <div className={`${styles.title}${props.titleClass ? ' ' + props.titleClass : ''}`} dangerouslySetInnerHTML={{__html: props.titleHtml}} />}

        {props.headline && props.headline.length > 0 &&
          <>
            {(() => {
              switch (props.headlineType || 'h2') {
                case 'h1':
                  return (<h1 className={headlineClassNames}>{props.headline}</h1>);
                case 'h2':
                  return (<h2 className={headlineClassNames}>{props.headline}</h2>);
                case 'h3':
                  return (<h3 className={headlineClassNames}>{props.headline}</h3>);
                case 'h4':
                  return (<h4 className={headlineClassNames}>{props.headline}</h4>);
                case 'h5':
                  return (<h5 className={headlineClassNames}>{props.headline}</h5>);
                case 'h6':
                  return (<h6 className={headlineClassNames}>{props.headline}</h6>);
                default:
                  return (<strong className={headlineClassNames}>{props.headline}</strong>);
              }
            })()}
          </>
        }

        {props.htmlHeadline &&
            <div className={headlineClassNames} dangerouslySetInnerHTML={{__html: props.htmlHeadline}} />
        }

        {props.htmlText && <div className={`${styles.text}${props.textClass ? ' ' + props.textClass : ''}`} dangerouslySetInnerHTML={{__html: props.htmlText}} /> }
        {props.children && <div className={`${styles.text}${props.textClass ? ' ' + props.textClass : ''}`}>{props.children}</div>}
      </div>
  );
}
