import React from "react"

import FormContainer from './../../components/form/container'
import Form from './_form'

export default props => (
    <FormContainer locale={props.locale}>
      <Form {...props} />
    </FormContainer>
)
