import React from "react"
import {useFormik} from 'formik' // @see https://formik.org/docs/overview

import Checkbox from './control/checkbox'

import styles from './component.module.less'

export default props => {
  if (!props.schema) {
    throw new Error('missing required property "schema"');
  }
  if (!props.fields) {
    throw new Error('missing required property "fields"');
  }

  let classNames = [styles.form];
  if (props.theme && props.theme === 'light') {
    classNames.push(styles.light);
  }
  if (props.className) {
    classNames.push(props.className);
  }
  classNames = classNames.join(' ');

  const formId = props.id || 'f';

  let initValues = {};
  for (let k of Object.keys(props.schema.fields)) {
    initValues[k] = props.schema.fields[k].default();
    if (initValues[k] !== undefined) {
      continue;
    }

    switch (props.schema.fields[k].type) {
      case "bool":
        initValues[k] = false;
        break;
      case "number":
        initValues[k] = 0;
        break;
      case "object":
        initValues[k] = {};
        break;
      case "array":
        initValues[k] = [];
        break;
      // case "date":
      // case "mixed":
      // case "string":
      default:
        initValues[k] = '';
        break;
    }
  }

  const renderError = (msg) => {
    if (props.hideErrorMessages) return null;
    return (
        <div className={styles.hasErrorLabel}>
          {msg}
        </div>
    );
  }

  const formik = useFormik({
    initialValues: initValues,
    validationSchema: props.schema,
    onSubmit: (values, { setSubmitting }) => {
      (props.onSubmit || (() => true))(values, setSubmitting, formik.resetForm);
    },
  });

  return (
      <form
          className={classNames}
          onSubmit={formik.handleSubmit}
      >
        {Object.keys(props.fields).map((name, i) => {

          const fId = formId + '-' + name.toLowerCase();
          const isRequired = props.schema.fields[name]._exclusive.required || false;
          const hasError = formik.touched[name] && formik.errors[name];

          let stateClass = [];
          if (hasError) stateClass.push(styles.hasError);
          if (stateClass.length > 0) {
            stateClass = ' ' + stateClass.join(' ');
          } else {
            stateClass = '';
          }

          switch (props.fields[name].type) {
            case 'select':
              return (
                  <label key={i} className={`${styles.formFieldLabel} ${styles.formFieldContainer}`}>
                    {props.fields[name].label}{isRequired && <>*</>}
                    <select
                        onChange={(e) => {
                          formik.handleChange(e);
                          if (e.target.value.length > 0) {
                            e.target.dataset['sel'] = '1';
                          } else {
                            delete e.target.dataset['sel'];
                          }
                        }}
                        onBlur={formik.handleBlur}
                        id={fId}
                        name={name}
                        value={formik.values[name]}
                        className={`${styles.formField}${stateClass}`}
                    >
                      <option value="">{props.fields[name].placeholder}</option>
                      {props.fields[name].values.map((val, j) => (
                          <option key={j} value={val}>{val}</option>
                      ))}
                    </select>
                    {hasError && renderError(formik.errors[name])}
                  </label>
              );
            case 'textarea':
              return (
                  <div key={i} className={styles.formFieldContainer}>
                    <label className={styles.formFieldLabel}>
                      {props.fields[name].label}{isRequired && <>*</>}
                      <textarea
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          id={fId}
                          name={name}
                          placeholder={props.fields[name].placeholder}
                          value={formik.values[name]}
                          className={`${styles.formField}${stateClass}`}
                      />
                    </label>
                    {hasError && renderError(formik.errors[name])}
                  </div>
              );
            case 'checkbox':
              return (
                  <Checkbox
                      key={i}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      id={fId}
                      name={name}
                      checked={formik.values[name]}
                      error={hasError && renderError(formik.errors[name])}
                      className={styles.cbox}
                  >
                    {!Array.isArray(props.fields[name].labelParts) &&
                        <>
                          {props.fields[name].labelLinkUrl &&
                              <a className={styles.cboxLink} href={props.fields[name].labelLinkUrl} target="_blank" rel="noreferrer">
                                {props.fields[name].label}
                                {isRequired && <>*</>}
                              </a>
                          }
                          {!props.fields[name].labelLinkUrl &&
                              <>
                                {props.fields[name].label}
                                {isRequired && <>*</>}
                              </>
                          }
                        </>
                    }

                    {Array.isArray(props.fields[name].labelParts) &&
                        <>
                          {props.fields[name].labelParts.map((t, j) => {
                            if (j % 2 === 0) return t;
                            return (<a className={styles.cboxLink} href={props.fields[name].labelLinkUrl} target="_blank" rel="noreferrer" key={j}>{t}</a>);
                          })}
                          {isRequired && <>*</>}
                        </>
                    }
                  </Checkbox>
              );
            default:
              return (
                  <div key={i} className={styles.formFieldContainer}>
                    <label className={styles.formFieldLabel} htmlFor={fId}>
                      {props.fields[name].label}{isRequired && <>*</>}
                    </label>
                    <input
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id={fId}
                        name={name}
                        type={props.fields[name].type}
                        placeholder={props.fields[name].placeholder}
                        value={formik.values[name]}
                        className={`${styles.formField}${stateClass}`}
                    />
                    {hasError && renderError(formik.errors[name])}
                  </div>
              );
          }
        })}

        {props.children}
      </form>
  );
}
